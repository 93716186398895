import React from 'react'

import Box from '../components/box'
import Layout from '../components/layout'
import SEO from '../components/seo'

import style from './about.module.scss'

const AboutPage = () => (
	<Layout>
		<SEO title="About" keywords={[`gatsby`, `application`, `react`]} />

		<section className={style.section}>
			<h1>Work</h1>
			<Box
				time="2020 &ndash;"
				title="Software Engineer"
				subtitle="Nationwide">
				<p></p>
			</Box>

			<Box
				time="2017 &ndash; 2020"
				title="Senior Web Developer"
				subtitle="equimedia">
				<p></p>
			</Box>

			<Box
				time="2014 &ndash; 2017"
				title="Web Developer"
				subtitle="equimedia">
				<p></p>
			</Box>

			<Box
				time="2014"
				title="Software Engineer"
				subtitle="Rentify">
				<p></p>
			</Box>

			<Box
				time="2012 &ndash; 2014"
				title="Web Developer"
				subtitle="gb-web">
				<p></p>
			</Box>
		</section>


		<section className={style.section}>
			<h1>Learn</h1>
			<Box
				time="2011 "
				title="BSC Management &amp; Psychology"
				subtitle="UWE">
				<p></p>
			</Box>
		</section>


		<section className={style.section}>
			<h1>Projects</h1>
			<Box
				title="HN PWA"
        url="https://hnpwa.leekiernan.co.uk">
				<p></p>
			</Box>
		</section>
	</Layout>
)

export default AboutPage
